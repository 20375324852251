import { WindowLocation } from "@reach/router";
import { graphql, navigate } from "gatsby";
import React, { useMemo } from "react";
import ArticleCard from "../components/ArticleCard/ArticleCard";
import CourseCard from "../components/CourseCard/CourseCard";
import EventCard from "../components/EventCard/EventCard";
import EventModal from "../components/EventModal/EventModal";
import GenericCard from "../components/GenericCard/GenericCard";
import LevelIndicator from "../components/LevelIndicator/LevelIndicator";
import MakeCard from "../components/MakeCard/MakeCard";
import ResourceCard from "../components/ResourceCard/ResourceCard";
import TeachFeaturedContent from "../components/TeachFeaturedContent/TeachFeaturedContent";
import Text from "../components/Text/Text";
import UnitOfWorkCard from "../components/UnitOfWorkCard/UnitOfWorkCard";
import UnitOfWorkModal from "../components/UnitOfWorkModal/UnitOfWorkModal";
import GetText from "../i18n/GetText";
import PageLayout from "../layouts/PageLayout/PageLayout";
import {
  CardInternalLink,
  CommonContext,
  GenericCardFields,
  Level,
  SanitySocialDocument,
  SanityTranslatedDocument
} from "../model/common";
import { SanityEvent, SanityEventOccurrence } from "../model/events";
import { SanityUnitOfWork } from "../model/lessons";
import { SanityMake } from "../model/makes";
import { GlobalMenus } from "../model/menu";
import {
  FilterValue,
  RowTypes,
  SanityPdCollection,
  SanityPdCourse,
  SanityResourcePage,
  SanityTeachCampaign,
  SanityTeachFeaturedRow
} from "../model/teach";
import { urlForDocument, URLParts } from "../urls";
import { SiteArea } from "../utils/analytics";
import {
  eventModalUrl,
  useEventOccurrences,
  useFocussedEventOccurrence
} from "../utils/events-util";
import { unitModalUrl, useFocussedUnitOfWork } from "../utils/unit-modal-util";

export const pageQuery = graphql`
  query EduPortalPage($_id: String!, $language: String, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityEduPortalPage(_id: { eq: $_id }) {
      _id
      _type
      title
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      content {
        title
        subtitle
        viewMoreText
        internalLink {
          reference {
            ...InternalLinkTarget
          }
          query
          fragment
        }
        _rawContent(resolveReferences: { maxDepth: 7 })
        externalLink
        type
        countries {
          code
          name
        }
        items {
          ... on SanityCardInternalLink {
            reference {
              ...InternalLinkTarget
            }
          }
          ... on SanityGenericCard {
            title
            description
            desktopImage {
              ...SanityImage
            }
            mobileImage {
              ...SanityImage
            }
            titleOverImage
            internalLink {
              reference {
                ...InternalLinkTarget
              }
              fragment
              query
            }
            externalLink
          }
        }
      }
    }
    makes: allSanityMake(
      filter: { language: { eq: $language } }
      sort: { fields: [_createdAt], order: DESC }
    ) {
      nodes {
        _id
        _type
        language
        slug {
          current
        }
        title
        description
        image {
          ...SanityImage
        }
        level
      }
    }
    unitOfWorkListingPage: sanityEduLessonsListingPage {
      _id
      _type
      title
    }
    unitsOfWork: allSanityUnitOfWork(
      filter: { unitType: { eq: "unitOfWork" } }
      sort: { fields: [_createdAt], order: DESC }
    ) {
      nodes {
        ...SanityUnitOfWorkForListing
      }
    }
    designChallenges: allSanityUnitOfWork(
      filter: { unitType: { eq: "designChallenge" } }
      sort: { fields: [_createdAt], order: DESC }
    ) {
      nodes {
        ...SanityUnitOfWorkForListing
      }
    }
    events: allSanityEvent(
      filter: { language: { eq: $language } }
      sort: { fields: [_createdAt], order: DESC }
    ) {
      nodes {
        ...SanityEvent
      }
    }
    resources: allSanityResource(
      filter: { language: { eq: $language } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        _id
        _type
        title
        subtitle
        image {
          ...SanityImage
        }
        slug {
          current
        }
      }
    }
    collections: allSanityPdCollection(sort: { fields: [title], order: ASC }) {
      nodes {
        _id
        title
        courses {
          _id
          _type
          title
          slug {
            current
          }
          description
          image {
            ...SanityImage
          }
        }
      }
    }
    config: sanityTeachConfig {
      campaigns {
        _id
        _rawContent(resolveReferences: { maxDepth: 2 })
        image {
          ...SanityMinimalImage
        }
        callToAction {
          title
          internalLink {
            reference {
              ...InternalLinkTarget
            }
            query
            fragment
          }
          externalLink {
            href
          }
        }
        countries {
          code
          name
        }
      }
      featuredMakes {
        _id
        _type
        language
        slug {
          current
        }
        title
        description
        image {
          ...SanityImage
        }
        level
      }
      featuredUnitsOfWork {
        ...SanityUnitOfWorkForListing
      }
      featuredResources {
        _id
      }
    }
  }
`;

const ageRanges = ["age-07-11yrs", "age-11-14yrs", "age-14-16yrs"];

const TeachFeatured = ({
  pageContext,
  data: {
    collections,
    config,
    events,
    makes,
    menus,
    page,
    resources,
    unitsOfWork,
    designChallenges
  },
  location
}: TeachFeaturedProps) => {
  const eventOccurrences = useEventOccurrences(events.nodes);
  const focussedEvent = useFocussedEventOccurrence(eventOccurrences);
  const focussedUnit = useFocussedUnitOfWork([
    ...unitsOfWork.nodes,
    ...designChallenges.nodes
  ]);
  const { title, content } = page;
  const { campaigns } = config;
  const rowTypes: RowTypes = useMemo(() => {
    const cardForUnit = (unit: SanityUnitOfWork) => (
      <UnitOfWorkCard
        url={unitModalUrl(page, unit)}
        key={unit._id}
        unit={unit}
        readMore={false}
        textMaxLines={3}
      />
    );

    return {
      events: {
        data: (_row: SanityTeachFeaturedRow, filter: FilterValue | undefined) =>
          eventOccurrences.filter(
            o =>
              !filter ||
              filter._id === "all" ||
              o.event.category === filter?._id
          ),
        card: (event: SanityEventOccurrence) => {
          return (
            <EventCard
              url={eventModalUrl(page, event)}
              key={event._key}
              value={event}
            />
          );
        },
        filterValues: () => {
          const used = new Set(eventOccurrences.map(e => e.event.category));
          const ordered = [
            "webinar" as const,
            "studentCodeAlong" as const,
            "microbitLive" as const,
            "other" as const
          ].filter(c => used.has(c));
          return ["all", ...ordered].map(id => ({
            _id: id,
            name: <GetText id={`event-category-${id}`} />
          }));
        }
      },
      courses: {
        data: (_row: SanityTeachFeaturedRow, filter: FilterValue | undefined) =>
          collections.nodes
            .find(v => v._id === filter!._id)
            ?.courses.filter(c => c) || [],
        card: (course: SanityPdCourse) => {
          return (
            <CourseCard key={course._id} course={course} textMaxLines={3} />
          );
        },
        filterValues: () =>
          collections.nodes.map(collection => ({
            ...collection,
            name: collection.title
          }))
      },
      unitsOfWork: {
        data: (
          _row: SanityTeachFeaturedRow,
          filter: FilterValue | undefined
        ) => {
          if (filter?._id === "featured") {
            return config.featuredUnitsOfWork.filter(
              // The featured list may contain both types. One to revisit?
              u => u.unitType === "unitOfWork"
            );
          }
          return unitsOfWork.nodes.filter(unit =>
            unit.ageRange.some(a => a === filter?._id)
          );
        },
        card: cardForUnit,
        filterValues: () => {
          const ranges = ["featured", ...ageRanges];
          return ranges.map(id => ({
            _id: id,
            name: <GetText id={id} />
          }));
        }
      },
      designChallenges: {
        data: (
          _row: SanityTeachFeaturedRow,
          filter: FilterValue | undefined
        ) => {
          if (filter?._id === "all-age-groups") {
            return designChallenges.nodes;
          }
          return designChallenges.nodes.filter(unit =>
            unit.ageRange.some(a => a === filter?._id)
          );
        },
        card: cardForUnit,
        filterValues: () => {
          const ranges = ["all-age-groups", ...ageRanges];
          return ranges.map(id => ({
            _id: id,
            name: <GetText id={id} />
          }));
        }
      },
      makes: {
        data: (
          _row: SanityTeachFeaturedRow,
          filter: FilterValue | undefined
        ) => {
          if (filter?._id === "featured") {
            return config.featuredMakes;
          }
          return makes.nodes.filter(m => m.level === filter?._id);
        },
        card: (make: SanityMake) => (
          <MakeCard key={make._id} make={make} widths={400} />
        ),
        filterValues: () => {
          const levels = ["beginner", "intermediate", "advanced"].map(
            level => ({
              _id: level,
              name: (
                <Text variant="default">
                  <LevelIndicator value={level as Level} />
                </Text>
              )
            })
          );
          return [
            {
              _id: "featured",
              name: (
                <Text variant="default">
                  <GetText id="featured" />
                </Text>
              )
            },
            ...levels
          ];
        }
      },
      resources: {
        data: () => {
          const allById = new Map<string, SanityResourcePage>(
            resources.nodes.map(n => [n._id, n])
          );
          const result: SanityResourcePage[] = [];
          config.featuredResources.forEach(({ _id }) => {
            const v = allById.get(_id);
            if (v) {
              result.push(v);
              allById.delete(_id);
            }
          });
          // For now at least we add the remaining resources to the end.
          // This may need reconsidering as the list grows.
          result.push(...allById.values());
          return result;
        },
        card: (resource: SanityResourcePage) => (
          <ResourceCard key={resource._id} resource={resource} />
        )
      },
      manual: {
        data: (row: SanityTeachFeaturedRow) => row.items ?? [],
        card: (item: GenericCardFields | CardInternalLink) =>
          item._type === "cardInternalLink" ? (
            <ArticleCard
              key={item.reference._id}
              article={item.reference}
              readMore={false}
              widths={400}
              textMaxLines={3}
            />
          ) : (
            <GenericCard
              key={item.title}
              layout="tile"
              card={item}
              widths={400}
              readMore={false}
              textMaxLines={3}
            />
          )
      }
    };
  }, [
    page,
    eventOccurrences,
    collections.nodes,
    unitsOfWork.nodes,
    config.featuredUnitsOfWork,
    config.featuredMakes,
    config.featuredResources,
    designChallenges.nodes,
    makes.nodes,
    resources.nodes
  ]);

  return (
    <PageLayout
      siteArea={SiteArea.TEACH}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      {focussedUnit && (
        <UnitOfWorkModal
          isOpen={true}
          onClose={() => navigate(urlForDocument(page))}
          value={focussedUnit}
        />
      )}
      {focussedEvent && (
        <EventModal
          isOpen={true}
          onClose={() => navigate(urlForDocument(page))}
          value={focussedEvent}
        />
      )}
      <TeachFeaturedContent
        rows={content}
        rowTypes={rowTypes}
        campaigns={campaigns}
      />
    </PageLayout>
  );
};

export default TeachFeatured;

interface SanityEduPortalPage
  extends SanityTranslatedDocument,
    SanitySocialDocument {
  title: string;
  content: SanityTeachFeaturedRow[];
}

interface SanityTeachConfig {
  campaigns: SanityTeachCampaign[];
  featuredMakes: SanityMake[];
  featuredUnitsOfWork: SanityUnitOfWork[];
  // Just the ids here as we display everything with these first.
  featuredResources: Array<{ _id: string }>;
}

interface TeachFeaturedProps {
  data: {
    menus: GlobalMenus;
    events: { nodes: SanityEvent[] };
    eventListingPage: URLParts;
    page: SanityEduPortalPage;
    makes: { nodes: SanityMake[] };
    makeListingPage: URLParts;
    unitsOfWork: { nodes: SanityUnitOfWork[] };
    designChallenges: { nodes: SanityUnitOfWork[] };
    unitOfWorkListingPage: URLParts;
    collections: { nodes: SanityPdCollection[] };
    resources: { nodes: SanityResourcePage[] };
    resourceListingPage: URLParts;
    config: SanityTeachConfig;
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
